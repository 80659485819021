import supportEn from './en/en.json'
import supportAr from './ar/ar.json'
import supportEl from './el/el.json'

const SupportMessages = {
  en: { support: { ...supportEn } },
  ar: { support: { ...supportAr } },
  el: { support: { ...supportEl } },
}

export default SupportMessages
