import { format } from 'date-fns'
import { ref, watch } from 'vue'
import useLocale from './useLocale'
import { getLocale } from '@/registerModules'

export default function useFormatDate(date: string, formatter = 'dd/MM/yyyy') {
  const { currentLocale } = useLocale()
  const formattedDate = ref('')

  watch(
    currentLocale,
    (locale) => {
      formattedDate.value = format(new Date(date), formatter, {
        locale: locale === 'en' ? undefined : getLocale(locale),
      })
    },
    {
      deep: true,
      immediate: true,
    }
  )

  return {
    formattedDate,
  }
}
