import tripsEn from './en/en.json'
import tripsAr from './ar/ar.json'
import tripsEl from './el/el.json'

const TripsMessages = {
  en: { trips: { ...tripsEn } },
  ar: { trips: { ...tripsAr } },
  el: { trips: { ...tripsEl } },
}

export default TripsMessages
