import indexEn from './en/en.json'
import indexAr from './ar/ar.json'
import indexEl from './el/el.json'

const IndexMessages = {
  en: { index: { ...indexEn } },
  ar: { index: { ...indexAr } },
  el: { index: { ...indexEl } },
}

export default IndexMessages
