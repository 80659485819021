import profileEn from './en/en.json'
import profileAr from './ar/ar.json'
import profileEl from './el/el.json'

const ProfileMessages = {
  en: { profile: { ...profileEn } },
  ar: { profile: { ...profileAr } },
  el: { profile: { ...profileEl } },
}

export default ProfileMessages
