import loyaltyEn from './en/en.json'
import loyaltyAr from './ar/ar.json'
import loyaltyEl from './el/el.json'

const LoyaltyMessages = {
  en: { loyalty: { ...loyaltyEn } },
  ar: { loyalty: { ...loyaltyAr } },
  el: { loyalty: { ...loyaltyEl } },
}

export default LoyaltyMessages
