import bookEn from './en/en.json'
import bookAr from './ar/ar.json'
import bookEl from './el/el.json'

const BookMessages = {
  en: { book: { ...bookEn } },
  ar: { book: { ...bookAr } },
  el: { book: { ...bookEl } },
}

export default BookMessages
