import moreEn from './en/en.json'
import moreAr from './ar/ar.json'
import moreEl from './el/el.json'

const MoreMessages = {
  en: { more: { ...moreEn } },
  ar: { more: { ...moreAr } },
  el: { more: { ...moreEl } },
}

export default MoreMessages
