<template>
  <header :class="[fixedClasses]">
    <JContainer
      :class="
        isDisplaySearchContent && searchStore.isSearched ? 'pt-8' : 'py-12'
      "
    >
      <nav class="mx-auto mb-2 flex items-center" :class="isFixed && 'px-16'">
        <div class="flex-1">
          <button
            v-if="showBack"
            class="flex items-center justify-center rounded-md border border-line bg-gray-100 p-10 font-medium outline-transparent"
            @click="close"
          >
            <JIcon
              :name="
                currentLocaleItem.rtl
                  ? 'alt-arrow-right-line'
                  : 'alt-arrow-left-line'
              "
              class="text-metadata text-gray-500"
            />
          </button>

          <button
            v-if="showHelpIcon && isHelpLeft"
            class="rounded-md border border-line bg-white p-10"
            @click="helpStore.toggleHelpModal(true)"
          >
            <JIcon
              name="message-question"
              class="text-metadata text-gray-500"
            />
          </button>
        </div>

        <div
          v-if="title"
          class="flex items-center text-header font-bold"
          :class="[titleClass]"
        >
          {{ title }}
        </div>

        <JLogo v-if="showLogo" src="/logo/budgetFly.svg" />

        <div class="flex flex-1 justify-end">
          <button
            v-if="showHelpIcon && !isHelpLeft"
            class="rounded-md border border-line bg-white p-10"
            @click="helpStore.toggleHelpModal(true)"
          >
            <JIcon
              name="message-question"
              class="text-metadata text-gray-500"
            />
          </button>

          <ItinerarySummaryToggler v-if="showItinerary">
            <button
              class="relative rounded-md bg-primary p-10"
              @click="itinerarySummaryStore.toggle()"
            >
              <JIcon name="navigation" class="text-white" />

              <span
                v-if="itinerarySummaryStore.itineraryLength"
                class="absolute -right-8 -top-6 flex h-16 w-16 items-center justify-center rounded-full bg-danger text-metadata-limited font-medium text-white md:-right-8 md:text-black"
              >
                {{ itinerarySummaryStore.itineraryLength }}
              </span>
            </button>
          </ItinerarySummaryToggler>
        </div>
      </nav>
    </JContainer>

    <HeaderContentSearchHistory
      v-if="
        showSearchHistory && isDisplaySearchContent && searchStore.isSearched
      "
      :class="isFixed && 'px-16'"
    />
  </header>
</template>

<script lang="ts" setup>
import { JContainer, JIcon, JLogo } from '@journey/ui-kit'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import HeaderContentSearchHistory from '../Header/HeaderContent/HeaderContentSearchHistory/HeaderContentSearchHistory.vue'
import type { Emits, Props } from './TheMobileHeader.types'
import { useLocale } from '@/modules/Core/composables'
import useHelpStore from '@/modules/Help/store'
import useItinerarySummaryStore from '@/modules/Search/store/itinerarySummary'
import ItinerarySummaryToggler from '@/modules/Search/components/ItinerarySummary/ItinerarySummaryToggler/ItinerarySummaryToggler.vue'
import useSearchStore from '@/modules/Search/store'

const props = withDefaults(defineProps<Props>(), {
  showBack: false,
  backTitle: '',
  showHelpIcon: true,
  closeHelp: true,
  showItinerary: false,
  showLogo: false,
  isHelpLeft: false,
  showSearchHistory: false,
})

const emit = defineEmits<Emits>()

const helpStore = useHelpStore()
const itinerarySummaryStore = useItinerarySummaryStore()

function close() {
  if (props.closeHelp) {
    helpStore.toggleHelpModal(false)
  }

  emit('back')
}

const { currentLocaleItem } = useLocale()

const route = useRoute()
const searchStore = useSearchStore()
const isDisplaySearchContent = computed(() => route.name === 'Result')

const fixedClasses = computed(
  () => props.isFixed && 'fixed left-0 top-0 z-1 w-full'
)
</script>
