import { defineStore } from 'pinia'
import { differenceInDays } from 'date-fns'
import type { CarInfo } from '@/modules/Search/types/model/carInfo'
import type { Car } from '@/modules/Search/types/model/search'

interface State {
  cars: Maybe<Car[]>
  selectedCar: Maybe<CarInfo>
  totalCarCount: number
  totalCarPageCount: number
  isLoading: boolean
  isSearchEmpty: boolean
}

const INITIAL_STATE: State = {
  cars: null,
  selectedCar: null,
  totalCarCount: 0,
  totalCarPageCount: 0,
  isLoading: false,
  isSearchEmpty: true,
}

const useCarSearchResultsStore = defineStore('carSearchResult', {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    carsList(state) {
      return state.cars ?? []
    },
    carAvailabilityDurationInDays(state) {
      if (!state.selectedCar) {
        return
      }

      return (
        differenceInDays(
          new Date(state.selectedCar.dropoffDateTime),
          new Date(state.selectedCar.pickupDateTime)
        ) + 1
      )
    },
    findCarByUniqueId:
      (state) =>
      (uniqueId: string): Maybe<Car> => {
        if (!state.cars) {
          return null
        }

        return state.cars.find((car) => car.uniqueId === uniqueId)
      },
  },
  actions: {
    setCars(value: Car[]) {
      this.cars = value
    },
    setSelectedCarInfo(selectedCar: Maybe<CarInfo>) {
      this.selectedCar = selectedCar
    },
    resetCars() {
      this.cars = []
    },
    appendCars(cars: Maybe<Car[]>) {
      if (!cars) {
        this.cars = cars
        return
      }

      if (!this.cars) {
        this.cars = []
      }

      this.cars?.push(...cars)
    },
    setTotalCarCount(totalCarCount: number) {
      this.totalCarCount = totalCarCount
    },
    setTotalCarPages(totalCarPageCount: number) {
      this.totalCarPageCount = totalCarPageCount
    },
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading
    },
    setIsSearchEmpty(value: boolean) {
      this.isSearchEmpty = value
    },
  },
})

export default useCarSearchResultsStore
