import searchEn from './en/en.json'
import searchAr from './ar/ar.json'
import searchEl from './el/el.json'

const SearchMessages = {
  en: { search: { ...searchEn } },
  ar: { search: { ...searchAr } },
  el: { search: { ...searchEl } },
}

export default SearchMessages
