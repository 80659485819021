import messagesEn from './en/en.json'
import messagesAr from './ar/ar.json'
import messagesEl from './el/el.json'

const MessagesMessages = {
  en: { messages: { ...messagesEn } },
  ar: { messages: { ...messagesAr } },
  el: { messages: { ...messagesEl } },
}

export default MessagesMessages
