import { defineStore } from 'pinia'
import type { FlightDetail } from '../types/model/flightDetail'
import useSearchPanelHistoryStore from './searchPanelHistory'
import type { Flight } from '@/modules/Search/types/model/search'

interface State {
  flights: Maybe<Flight[]>
  selectedFlight: Maybe<FlightDetail>
  totalFlightCount: number
  totalFlightPageCount: number
  isLoading: boolean
  filteredCity: string | undefined
  isSearchEmpty: boolean
}

const INITIAL_STATE: State = {
  flights: null,
  isLoading: false,
  selectedFlight: null,
  totalFlightCount: 0,
  totalFlightPageCount: 0,
  filteredCity: undefined,
  isSearchEmpty: true,
}

const useFlightSearchResultsStore = defineStore('flightSearchResults', {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    flightsList(state) {
      return state.flights ?? []
    },
    selectedFlightOrigin(state) {
      if (!state.selectedFlight) {
        return null
      }

      return state.selectedFlight.flightInfo.slices[0].segments[0].origin
    },
    selectedFlightLastDestination(state) {
      if (!state.selectedFlight) {
        return null
      }

      const searchPanelHistoryStore = useSearchPanelHistoryStore()

      let lastFlight = state.selectedFlight.flightInfo.slices.length - 1

      if (searchPanelHistoryStore.history.main.tripType === 'RoundTrip') {
        lastFlight -= 1
      }

      return state.selectedFlight.flightInfo.slices[lastFlight].segments[
        state.selectedFlight.flightInfo.slices[lastFlight].segments.length - 1
      ].destination
    },
    selectedFlightNumberOfPassengers(state) {
      if (!state.selectedFlight) {
        return null
      }

      return state.selectedFlight.flightInfo.passengers.length
    },
    findFlightById:
      (state) =>
      (flightId: string): Maybe<Flight> => {
        if (!state.flights) {
          return null
        }

        return state.flights.find((flight) => flight.id === flightId)
      },
  },
  actions: {
    setFlights(flights: Maybe<Flight[]>) {
      this.flights = flights
    },
    resetFlights() {
      this.flights = []
    },
    appendFlights(flights: Maybe<Flight[]>) {
      if (!flights) {
        this.flights = flights
        return
      }

      if (!this.flights) {
        this.flights = []
      }

      this.flights?.push(...flights)
    },
    setSelectedFlight(selectedFlight: Maybe<FlightDetail>) {
      this.selectedFlight = selectedFlight
    },
    setTotalFlightCount(totalFlightCount: number) {
      this.totalFlightCount = totalFlightCount
    },
    setTotalFlightPages(totalFlightPageCount: number) {
      this.totalFlightPageCount = totalFlightPageCount
    },
    setIsLoading(loading: boolean) {
      this.isLoading = loading
    },
    setFilteredCity(city: string | undefined) {
      this.filteredCity = city
    },
    setIsSearchEmpty(value: boolean) {
      this.isSearchEmpty = value
    },
  },
})

export default useFlightSearchResultsStore
