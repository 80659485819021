import paymentEn from './en/en.json'
import paymentAr from './ar/ar.json'
import paymentEl from './el/el.json'

const PaymentMessages = {
  en: { payment: { ...paymentEn } },
  ar: { payment: { ...paymentAr } },
  el: { payment: { ...paymentEl } },
}

export default PaymentMessages
