import authEn from './en/en.json'
import authAr from './ar/ar.json'
import authEl from './el/el.json'

const AuthMessages = {
  en: { auth: { ...authEn } },
  ar: { auth: { ...authAr } },
  el: { auth: { ...authEl } },
}

export default AuthMessages
