import helpEn from './en/en.json'
import helpAr from './ar/ar.json'
import helpEl from './el/el.json'

const HelpMessages = {
  en: { help: { ...helpEn } },
  ar: { help: { ...helpAr } },
  el: { help: { ...helpEl } },
}

export default HelpMessages
